$stages: (
  stage-1: #f3d124,
  stage-2: #f5613c,
  stage-3: #a72c88,
  stage-4: #73278f,
  stage-5: #4d4c4d,
  input-border: #dbdbdb,
  light-grey: #f4f4f4,
  medium-grey: #cccccc,
  orange-lighten: rgba($orange, 0.75),
);

@mixin steps-segments-colors {
  @each $var in $stages {
    $key: nth($var, 1);
    $value: nth($var, 2);

    @each $name, $pair in $colors {
      .is-background-#{$name} {
        background-color: nth($pair, 1);
        color: nth($pair, 2);
      }

      .steps-marker.is-#{$name} {
        background-color: nth($pair, 1);
        color: #fff !important;
      }

      .steps-segment.is-#{$name}.is-active {
        &::after {
          background-color: nth($pair, 1) !important;
        }
      }
    }
  }
  .steps-segment.is-disabled {
    &::after {
      background-color: rgba(#f5f5f5, 1) !important;
    }
  }
  .tooltip.is-tooltip-stage-1::before {
    color: white !important;
  }
}
.container.is-fluid {
  height: 100vh;
  margin: 0 !important;
}

.dropdown-content {
  max-height: 20rem;
  overflow-y: auto;
}

.button.is-custom-light {
  background-color: #fff;
  border-color: #f5f5f5;
  color: #b3b3b3 !important;
  line-height: 0rem;
}

.dropdown.is-fullwidth {
  color: $beige-light;
  display: flex;
  height: auto;

  .dropdown-trigger,
  .dropdown-menu {
    width: 100%;
  }

  .button.is-fullwidth {
    display: flex;
    justify-content: space-between;
    height: $dropdown-height;

    span {
      color: #363636;
      font-weight: 400;
    }
  }
}

.button.is-orange-lighten,
.button.is-light-grey,
.button.is-medium-grey {
  span {
    // color: #ffffff;
    font-size: 0.625rem;
    font-weight: 900;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }
}

@function create-colors($list) {
  $custom-colors: ();

  @each $stage in $list {
    $key: nth($stage, 1);
    $value: nth($stage, 2);
    $invert: findColorInvert($value);
    $custom-colors: map-merge(
      $custom-colors,
      (
        quote($key): (
          $value,
          $invert,
        ),
      )
    );
  }

  @return $custom-colors;
}

$custom-colors: (create-colors($stages));

// @import "../../node_modules/bulma/sass/utilities/initial-variables.sass";
// @import "../../node_modules/bulma/sass/utilities/functions.sass";
// @import "../../node_modules/bulma/sass/utilities/derived-variables.sass";
// @import "../../node_modules/bulma/sass/utilities/animations.sass";
// @import "../../node_modules/bulma/sass/utilities/mixins.sass";
// @import "../../node_modules/bulma/sass/utilities/controls.sass";

@import "../../node_modules/bulma/sass/utilities/_all.sass";

@include steps-segments-colors;
