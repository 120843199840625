$datepicker__background-color: $white-bis !default;
$datepicker__border-color: $primary !default;
$datepicker__highlighted-color: $cyan !default;
$datepicker__muted-color: $grey-light !default;
$datepicker__selected-color: $primary !default;
$datepicker__text-color: $black !default;
$datepicker__header-color: $black !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color,
    10%) !default;

$datepicker__font-family: $family-sans-serif !default;

.react-datepicker-popper {
    z-index: 5 !important;
}

@import '../../node_modules/react-datepicker/src/stylesheets/datepicker.scss'