// Set your brand colors

$orange: #f58c39;
$pink: #fa7c91;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$bg-light:#fff;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: #b7b7b7 !default;
$grey-light: $beige-light;
$primary: $orange;

$link: $beige-light;

$link-hover-border: $beige-light;
$link-active-border: $beige-light;
$link-focus-border: $beige-light;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

$avatar-size: 30px;
$options-dots-size: 20px;

$dropdown-height: 3rem;

// Import only what you need from Bulma

// @import "../node_modules/bulma/sass/utilities/_all.sass";
@import "./scss/CustomUtilities.scss";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/components/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
@import "../node_modules/bulma-steps-component/bulma-steps.sass";
@import "./scss/Datepicker.scss";
@import "../node_modules/bulma-tooltip/src/sass/index.sass";
